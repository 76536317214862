import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export const YandexTrackerButton: FC = () => {
	const { project, user } = useAppSelector(state => state.environment)
	const { pathname } = useLocation()
	const id = '66cca7a3505690982aa17878'
	const yandexTrackerData = {
		name: user?.lastName + ' ' + user?.firstName,
		email: user?.email,
		myabsProject: project.name.replace('#', '%23'),
		myabsUrl: window.location.protocol + '//' + window.location.host + pathname
	}
	const yandexTrackerLink = Object.entries(yandexTrackerData)
		.map(yt => `${yt[0]}=${yt[1]}`)
		.join('&')
	return (
		<NavLink to={`https://forms.yandex.ru/cloud/${id}?${yandexTrackerLink}`} target="_blank">
			Сообщить об ошибке
		</NavLink>
	)
}
