import { CommentOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Col, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from 'hooks/appReduxHook'
import { useMemo } from 'react'
import { useAdjustmentPositionCommentsState } from 'widgets/adjustment-position-comments'
import { IAdjustmentComment } from '..'

export const useAdjustmentCommentsColumns = () => {
	const { user } = useAppSelector(state => state.environment)
	const columns = useMemo<ColumnsType<IAdjustmentComment>>(
		() => [
			{
				dataIndex: ['section', 'codifier'],
				title: 'Секция',
				width: 80,
				render: (value: string) =>
					value[value.length - 1] === '.' ? value.substring(0, value.length - 1) : value
			},
			{
				dataIndex: 'order',
				title: '№',
				width: 80,
				align: 'center'
			},
			{
				dataIndex: 'name',
				title: 'Позиция сметы'
			},
			{
				width: 96,
				dataIndex: 'id',
				render: (_, record) => (
					<Row wrap={false} align="middle" gutter={8}>
						<Col>
							<Button
								size="small"
								type="text"
								icon={<CommentOutlined />}
								onClick={() =>
									useAdjustmentPositionCommentsState.setState({
										show: true,
										currentId: record.id
									})
								}
							/>
						</Col>
						<Col>
							<Tooltip title={record.isCommentsClosed ? 'Открыть чат' : 'Закрыть чат'}>
								<Button
									disabled={user?.id !== record.mainCommentAuthor?.id}
									type="text"
									danger={!record.isCommentsClosed}
									icon={record.isCommentsClosed ? <UnlockOutlined /> : <LockOutlined />}
								/>
							</Tooltip>
						</Col>
					</Row>
				)
			}
		],
		[]
	)
	return columns
}
